import React from "react";
import styled from "styled-components";

const Message = ({ title, children, style }) => {
  return (
    <Section style={style}>
      <h3
        style={{
          fontSize: "1.3rem",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "1rem",
        }}
      >
        {title}
        <br />
      </h3>
      {children}
    </Section>
  );
};

export default Message;

const Section = styled.section`
  border: 1px solid #e5eff5;
  boxshadow: 0 2px 3px rgb(3 27 78 / 5%);
  borderradius: var(--radius);
  padding: 2rem 1.5rem;
  margin: 4rem 0;
  text-align: center;
`;
