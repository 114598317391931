import React from "react";
import { graphql } from "gatsby";

import PostTemplate from "../components/templates/post.template";

const ComponentName = ({ data, pageContext, location }) => {
  return (
    <PostTemplate
      data={data}
      rootPath={pageContext.rootPath}
      locationPathname={location.pathname}
      categoriesSummary={pageContext.categoriesSummary}
    />
  );
};

export const query = graphql`
  query GetSingleBlog($slug: String, $category: String) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "DD MMMM YYYY", locale: "fr")
      body {
        body
      }
      description {
        description
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
      contributors {
        name
      }
      categories
      readTime
    }
    relatedPosts: allContentfulBlogPost(
      filter: { categories: { eq: $category }, slug: { ne: $slug } }
      sort: { fields: publishDate, order: DESC }
      limit: 3
    ) {
      nodes {
        id
        title
        slug
        description {
          description
        }
        body {
          body
        }
        publishDate(formatString: "DD.MMMM.YYYY", locale: "fr")
        categories
        readTime
        heroImage {
          file {
            fileName
          }
          fluid {
            ...GatsbyContentfulFluid
          }
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 100
            height: 50
            resizingBehavior: FILL
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

export default ComponentName;
