import React from "react";
import { FaClock } from "react-icons/fa";
import styled from "styled-components";

import Title from "./title";
import { Link } from "gatsby";
import Card from "./ui/card";
import BlogCategories from "../components/blog-categories";

export const BLOGS_PER_PAGE = 12;
export const BLOG_INCREMENT = 8;
export interface ICategorySummary {
  totalBlogPostsByCategory: {
    totalCount: number;
    category: string;
  }[];
  totalBlogPosts: number;
}

export const Blogs = ({
  blogPosts,
  categories,
  title,
  showLink,
  style,
  noDescription,
  fit,
  rootPath,
  fullWidth,
  categoriesSummary,
}) => {
  return (
    <section
      className="section"
      style={{
        ...style,
        width: "90vw",
        maxWidth: "1170px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {blogPosts.length > 0 && <Title title={title} />}
      {!showLink && (
        <BlogCategories
          categories={categories}
          show
          rootPath={rootPath}
          style={{
            width: "90vw",
            maxWidth: "1170px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          categoriesSummary={categoriesSummary}
        />
      )}
      <div
        className={`section-center ${
          fit
            ? "blogs-center-fit"
            : fullWidth
            ? "blogs-center-full"
            : "blogs-center"
        }`}
      >
        {blogPosts.map((blogPost) => {
          return (
            <Card
              key={blogPost.id}
              title={blogPost.title}
              description={
                noDescription ? null : blogPost.description?.description
              }
              url={`${rootPath}${blogPost.slug}/`}
              gatsbyImage={blogPost.heroImage}
            >
              <CardFooter>
                {!noDescription && blogPost.categories && (
                  <p>{blogPost.categories[0]}</p>
                )}
                <p style={{ display: "flex", alignItems: "center" }}>
                  <FaClock style={{ marginRight: "0.2rem" }} />
                  {blogPost.readTime} min
                </p>
              </CardFooter>
            </Card>
          );
        })}
      </div>
      {showLink && (
        <Link to={rootPath} className="btn center-btn">
          {blogPosts.length > 0 ? "Voir plus" : "Tous les articles"}
        </Link>
      )}
    </section>
  );
};

export default Blogs;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
    font-weight: bold;
    color: var(--clr-grey-5);
  }

  p:first-of-type {
    display: inline-block;
    background: var(--clr-grey-9);
    color: var(--clr-grey-5);
    margin-right: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.7rem;
  }

  @media screen and (max-width: 340px) {
    font-size: small;
    p:first-of-type {
      font-size: x-small;
    }
  }

  @media screen and (max-width: 300px) {
    font-size: 0.6rem;
    p:first-of-type {
      font-size: 0.6rem;
    }
  }

  @media screen and (max-width: 1080px) {
    font-size: 0.85rem;
    p:first-of-type {
      font-size: 0.85rem;
    }
  }
`;
