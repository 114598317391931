import * as React from "react";
import {
  FaFacebook,
  FaWhatsapp,
  FaTwitter,
  FaLinkedin,
  FaLink,
} from "react-icons/fa";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

const data = [
  {
    icon: <FaFacebook className="social-icon"></FaFacebook>,
    url: "https://www.facebook.com/sharer.php?u=",
  },
  {
    icon: <FaWhatsapp className="social-icon"></FaWhatsapp>,
    url: "https://api.whatsapp.com/send?text=",
  },
  {
    icon: <FaTwitter className="social-icon"></FaTwitter>,
    url: "https://twitter.com/intent/tweet?url=&text=&via=",
  },
  {
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com/sharing/share-offsite/?url=",
  },
  {
    icon: <FaLink className="social-icon"></FaLink>,
    url: "https://www.linkedin.com/shareArticle?url=&title=",
    placeholder: "link",
  },
];

const Share = ({ url, style }) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  const onShare = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <CardFooter style={style}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: "var(--clr-grey-9)",
        }}
      >
        <p>Partager</p>
        <Ul url={url}>
          {data.map((link, i) => {
            return (
              <li key={i}>
                {link.placeholder === "link" ? (
                  <span
                    style={{ cursor: "pointer" }}
                    aria-hidden="true"
                    className="folowUp-link"
                    title="Copier le lien"
                    onClick={() =>
                      navigator.clipboard.writeText(`${siteUrl}${url}/`)
                    }
                  >
                    {link.icon}
                  </span>
                ) : (
                  <a
                    href="#"
                    className="folowUp-link"
                    onClick={(e) => {
                      e.preventDefault();
                      onShare(`${link.url}${siteUrl}${url}`);
                    }}
                  >
                    {link.icon}
                  </a>
                )}
              </li>
            );
          })}
        </Ul>
      </div>
    </CardFooter>
  );
};

export default Share;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
    color: var(--clr-grey-5);
  }

  p:first-of-type {
    display: inline-block;
    background: var(--clr-grey-9);
    color: var(--clr-grey-5);
    margin-right: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.85rem;
  }
`;

const Ul = styled.ul`
  display: flex !important;
  align-items: center;
  margin: 0 !important;
  li {
    list-style: none !important;
  }
`;
