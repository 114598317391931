import { FaCheckCircle } from "react-icons/fa";
import React from "react";
import { useForm, ValidationError } from "@formspree/react";

import Share from "./share";
import GTAButton from "../ui/gta-button";

function ContactForm({ article, author, sectionTitle, pathname }) {
  const [state, handleSubmit] = useForm(
    process.env.GATSBY_FORMSPREE_FEEDBACK_ID
  );
  const [showMore, setShowMore] = React.useState(false);

  const handleShowMore = () => {
    setShowMore(true);
  };

  const Success = () => {
    for (const form of document.getElementsByTagName("form")) {
      form.reset();
    }

    return (
      <div
        style={{
          border: "1px solid #e5eff5",
          boxShadow: "0 2px 3px rgb(3 27 78 / 5%)",
          borderRadius: "var(--radius)",
          padding: "2rem 1.5rem",
          margin: "4rem 0",
          textAlign: "center",
          background: "#d4edda",
        }}
      >
        <p style={{ fontSize: "1.3rem", fontWeight: "bold", color: "#155724" }}>
          <FaCheckCircle /> Nous avons reçu votre commentaire :) <br />
        </p>
      </div>
    );
  };

  return (
    <section
      style={{
        border: "1px solid #e5eff5",
        boxShadow: "0 2px 3px rgb(3 27 78 / 5%)",
        borderRadius: "var(--radius)",
        padding: "2rem 1.5rem",
        margin: "4rem 0",
        textAlign: "center",
      }}
    >
      {state.succeeded && <Success />}
      <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
        {sectionTitle ? sectionTitle : "Avez-vous aimé cet article ?"} <br />
      </p>
      <Share style={{ justifyContent: "center" }} url={pathname} />

      {showMore ? (
        <div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input type="text" name="_gotcha" style={{ display: "none" }} />
              <input
                type="text"
                name="name"
                placeholder="nom"
                className="form-control"
                required
              />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
              <input
                type="email"
                name="email"
                placeholder="email"
                className="form-control"
                required
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <input
                type="text"
                name="article"
                style={{ display: "none" }}
                value={article}
                readOnly
              />
              <input
                type="text"
                name="author"
                style={{ display: "none" }}
                value={author}
                readOnly
              />
              <textarea
                name="message"
                rows="5"
                placeholder="commentaire"
                className="form-control"
                required
              />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
            </div>
            <button
              type="submit"
              disabled={state.submitting}
              className={`submit-btn btn`}
            >
              Envoyer
            </button>
          </form>
        </div>
      ) : (
        <span aria-hidden="true" onClick={handleShowMore}>
          <GTAButton>Laissez-nous un commentaire</GTAButton>
        </span>
      )}
    </section>
  );
}

function Feadback({ article, author, sectionTitle, pathname }) {
  return (
    <ContactForm
      article={article}
      author={author}
      sectionTitle={sectionTitle}
      pathname={pathname}
    />
  );
}
export default Feadback;
