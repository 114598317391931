import * as React from "react";

const GTAButton = ({ children }) => {
  return (
    <span aria-hidden="true" className="gta-btn">
      {children}
    </span>
  );
};

export default GTAButton;
