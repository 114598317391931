import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import * as Utils from "../utils/utils";
import { ICategorySummary } from "./blogs";

const allCategories = "tous les articles";

const CategoryContainer = styled.section`
  margin-bottom: 2rem;
  ul {
    margin: 0 -0.625rem;
    padding: 0;

    li {
      display: inline-block;
      margin: 0.625rem 0.3125rem;
    }
  }

  // @media screen and (max-width: 768px) {
  //   margin: 0 2rem;
  // }
`;

const LinkContainer = styled(Link)`
  display: inline-block;
  height: 2.1875rem;
  padding: 0px 1.5rem;
  line-height: 2.1875rem;
  border: 1px solid rgb(230, 230, 230);
  text-decoration: none;
  border-radius: 1.25rem;
  color: rgb(34, 34, 34);

  :hover {
    color: var(--clr-primary-5);
  }
`;

const BlogCategories = ({
  categories,
  style,
  rootPath,
  hideAll,
  categoriesSummary,
}) => {
  const getCategoryCount = (category: string) =>
    (categoriesSummary as ICategorySummary)?.totalBlogPostsByCategory?.find(
      (i) => i.category === category
    )?.totalCount;

  return (
    <CategoryContainer style={style}>
      <ul>
        {hideAll === undefined && (
          <li key={allCategories}>
            <LinkContainer
              key={allCategories}
              to={rootPath}
              activeStyle={{ borderColor: "var(--clr-primary-5)" }}
            >
              {Utils.firstCharUpperCase(allCategories)}
              {(categoriesSummary as ICategorySummary)?.totalBlogPosts &&
                ` (${categoriesSummary.totalBlogPosts})`}
            </LinkContainer>
          </li>
        )}
        {categories?.map((category, i) => {
          const url = `${rootPath}theme/${Utils.transformSlug(category)}/`;
          const categoryCount = getCategoryCount(category);
          return (
            <li key={i}>
              <LinkContainer
                key={i}
                to={url}
                activeStyle={{ borderColor: "var(--clr-primary-5)" }}
              >
                {Utils.firstCharUpperCase(category)}
                {categoryCount && ` (${categoryCount})`}
              </LinkContainer>
            </li>
          );
        })}
      </ul>
    </CategoryContainer>
  );
};

export default BlogCategories;
