import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import styled from "styled-components";

const Card = ({ title, description, url, gatsbyImage, children }) => {
  const image = getImage(gatsbyImage);
  return (
    <CardContainer to={url}>
      <article>
        <GatsbyImage image={image} alt={title} />
        <CardContent>
          <h4>{title}</h4>
          <p>{description}</p>
          {children}
        </CardContent>
      </article>
    </CardContainer>
  );
};

export default Card;

Card.propTypes = {
  title: PropTypes.string.isRequired,
  gatsbyImage: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
};

const CardContent = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 1.5rem 1rem;

  h4 {
    color: var(--clr-grey-1);
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 4.3rem; /* fallback */
    min-height: 3.4rem; /* fallback */
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 1.2rem;
  }

  p {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.2rem; /* fallback */
    max-height: 2.5rem; /* fallback */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;

const CardContainer = styled(Link)`
  display: block;
  margin-bottom: 2rem;
  background: var(--clr-grey-10);
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  transition: var(--transition);

  &:hover {
    box-shadow: var(--dark-shadow);
    transform: scale(1.02);
  }

  article {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
  }
`;
