import * as React from "react";
import { Link } from "gatsby";

const GTAButtonLink = ({ url, children }) => {
  return (
    <Link className="gta-btn" to={url}>
      {children}
    </Link>
  );
};

export default GTAButtonLink;
